<template>
  <div v-if="gir" class="modal-gir">
    <div class="modal-gir-header">
      <div class="modal-gir-header__title">CHOISISSEZ LES DATES DE VOTRE PROCHAIN VOYAGE</div>
      <div class="close-action" @click="closeModal()">X</div>
    </div>
    <GirCard
      v-for="girItem in gir"
      :key="girItem.id"
      :gir-item="girItem"
      :travel-days="travelDays"
    />
  </div>
</template>
<script lang="ts" setup>
import { GirDateAndPrice } from '@/lib/strapi-types/components/GirDateAndPrice';

defineProps<{
  gir?: GirDateAndPrice[];
  travelDays?: number;
}>();
const emit = defineEmits(['update:modelValue']);

function closeModal() {
  emit('update:modelValue', false);
}
</script>
<style lang="scss" scoped>
@use '$/breakpoints.scss';

.modal-gir {
  display: flex;
  flex-direction: column;

  width: 100%;
  min-width: 600px;
  padding: 20px;

  background-color: white;
  border-radius: 8px;

  @include breakpoints.mobile() {
    min-width: 100%;
    padding-left: 10px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__title {
      margin-bottom: 1rem;
      font-size: 20px;
      font-weight: 700;
    }

    .close-action {
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 20px;
      height: 20px;
      padding: 15px;

      border: 1px solid #cccccc5e;
      border-radius: 50%;
      box-shadow: 3px 2px 8px rgba(0 0 0 / 20%);
    }
  }
}
</style>