<template>
  <div class="card">
    <div class="card__date">
      Du {{ startDate }} &nbsp;<span> Au {{ endDate }}</span>
    </div>
    <div class="card__price">{{ girItem.price }}€*</div>
    <div class="card__action">
      <button :class="girItem.status" @click="handleGir">{{ buttonLabel }}</button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { GirDateAndPrice } from '@/lib/strapi-types/components/GirDateAndPrice';
import { transformerDateReview } from '@/lib/utils';
import { BUTTON_LABELS_GIR } from '@/lib/constants';

const { girItem, travelDays } = defineProps<{
  girItem: GirDateAndPrice;
  travelDays?: number;
}>();
const route = useRoute();

const statusLabel = BUTTON_LABELS_GIR[girItem.status!];

const buttonLabel = computed(() => {
  return statusLabel === BUTTON_LABELS_GIR.disponible || statusLabel === BUTTON_LABELS_GIR.garantie
    ? 'Demander un devis'
    : statusLabel;
});

function handleGir() {
  if (statusLabel === BUTTON_LABELS_GIR.disponible || statusLabel === BUTTON_LABELS_GIR.garantie)
    navigateTo(
      `/${route.params.continentid}/${route.params.countryid}/formulaire-de-devis?journey=${route.params.tourid}&departureDate=${girItem.date}`
    );
}

function addDays(date: Date, numberOfDays: number = 0) {
  const dateObj = new Date(date);

  dateObj.setDate(dateObj.getDate() + numberOfDays);
  return dateObj.toISOString().split('T')[0];
}

const startDate = transformerDateReview(girItem.date.toString());
const endDate = transformerDateReview(addDays(girItem.date, travelDays) ?? '');
</script>
<style lang="scss" scoped>
@use '$/button.scss';
@use '$/colors.scss';
@use '$/breakpoints.scss';

.card {
  display: flex;

  width: 100%;
  height: 60px;
  margin: 5px 0;
  padding: 10px 0;

  &__date,
  &__price,
  &__action {
    flex: 1;
    align-items: center;
  }

  &__date {
    display: flex;
    flex: 2;
    flex-wrap: wrap;
    align-content: center;

    padding-right: 5px;

    border-right: 2px solid #47474786;

    @include breakpoints.mobile() {
      flex: none;
      max-width: 150px;
    }

    span {
      display: block;
    }
  }

  &__action {
    justify-content: center;

    @include breakpoints.mobile() {
      font-size: 10px;
    }

    .disponible,
    .garantie {
      @include button.plain(colors.$primary-yellow);

      padding: 8px;
      font-size: small;
      color: colors.$grey-900;
    }

    .complet {
      cursor: default;

      padding: 10px 30px;

      color: colors.$grey-900;

      background-color: colors.$grey-500;
      border-radius: 40px;

      @include breakpoints.mobile() {
        font-size: 1.5em;
      }
    }
  }

  &__price {
    justify-content: center;
    font-size: 24px;
    font-weight: 700;

    @include breakpoints.mobile() {
      font-size: 20px;
    }
  }
}
</style>