import { MvSequence } from '@/lib/strapi-types/components/MvSequence';

export default function useFindPlacesName(sequences?: MvSequence[]): string {
  if (!sequences || sequences.length < 1) return '';
  const placesWithDuplicatesKey = sequences
    .filter((sequence: MvSequence) => {
      return sequence.places.data.length > 0;
    })
    .map(sequence => sequence.places.data)
    .flat();

  const places = [...new Map(placesWithDuplicatesKey.map(place => [place.id, place])).values()];

  return places.map(place => place.attributes.label).join(' - ');
}
