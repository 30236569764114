<template>
  <div id="page">
    <LazySectionHero
      v-if="journey && heroImage"
      :title="journey.attributes.title"
      :journey="journey"
      :images="heroImage"
      :places-name="useFindPlacesName(journey?.attributes.sequences)"
      diaporama
    />
    <LazyBreadcrumb :parts="breadcrumbParts" />

    <LazySectionRecommendations
      v-if="country && journey?.attributes.strongPoint"
      :title="
        replacePlaceholders(`Points forts selon votre spécialiste {{preposition}} {{country}}`, {
          preposition: `${country.preposition}`,
          country: `${country.name}`
        })
      "
      :strong-points="journey.attributes.strongPoint"
    />

    <LazyClientOnly v-if="currentStep && journey">
      <Map
        class="map"
        :current-step="currentStep"
        :steps="placesCoordonees"
        width="100%"
        height="100%"
        @change-current-step="changeCurrentStep"
      />
    </LazyClientOnly>

    <LazySectionInfoTabs v-if="journey" :journey="journey" />

    <LazySectionRatings />

    <!-- NOTE: deactivated because journey has no lifestyle -->
    <!-- <SectionLifestyle :lifestyle="journey?.attributes.lifestyle" /> -->

    <LazySectionJourneySuggestions :country-context="countryContext" />

    <LazySectionRegion v-if="country?.name" :country="country" :country-context="countryContext" />

    <LazySectionExperts :country-context="countryContext" />
    <LazyStickyBar
      v-if="journey"
      v-model="isDisplayModal"
      :is-gir="journey?.attributes.GIR?.length > 0"
      :quotation-url="`/${country?.continent?.slug}/${countryContext}/formulaire-de-devis?journey=${journey?.attributes.slug}`"
    />

    <LazyModal v-if="isDisplayModal" v-model="isDisplayModal">
      <GirModal
        v-if="journey?.attributes.GIR"
        v-model="isDisplayModal"
        :gir="journey?.attributes.GIR"
        :travel-days="journey?.attributes.days"
      >
      </GirModal>
    </LazyModal>
  </div>
</template>

<script lang="ts" setup>
import { replacePlaceholders } from '@/lib/utils';
import JourneyType from '@/lib/types/enums/JourneyType';

const route = useRoute();
const isDisplayModal = $ref(false);
const { pushToDataLayer } = useDataLayer();
const headerStore = useHeaderStore();

const { listContext } = storeToRefs(headerStore);
const countryContext = route.params.countryid;
const country = listContext?.value?.[countryContext];

const { journey } = await useFindOneJourney({
  filters: { slug: route.params.tourid },
  populate: {
    strongPoint: true,
    sequences: {
      populate: {
        places: true,
        mv_activities: true
      }
    },
    activities: { populate: { mv_places: true } },
    mv_hotels: { populate: { places: true } },
    included: true,
    notIncluded: true,
    GIR: {
      sort: ['date:asc'],
      filters: {
        date: { $gt: new Date().toISOString() }
      }
    },
    goodToKnows: true,
    SEOMeta: true
  },
  key: route.params.tourid
});

if (!journey) {
  throw createError({
    statusCode: 404
  });
}

onMounted(() => {
  pushToDataLayer({
    BU_pageCategory: 'product',
    mid: 0,
    nodeType: 'tour',
    desti_id: '1',
    desti_name: 'marcovasco',
    pageType: 'product',
    productID: journey?.id,
    itemID: `${countryContext}_${journey?.id}`,
    productPrice: `${journey?.attributes.price}`,
    page_attr_pagecategory: 'produit',
    page_attr_level1: `${country?.continent?.slug}`,
    page_attr_level2: `${countryContext}`,
    page_attr_level3: `${countryContext}`,
    page_attr_tagname1: `${journey?.attributes.title}`,
    page_attr_articleid: `${journey?.id}`,
    page_attr_author: 'Marco Vasco',
    page_attr_pays: `${countryContext}`,
    page_attr_montant: `${journey?.attributes.price}`,
    page_attr_userstatus: 'incognito',
    page_attr_userstate: 'not-connected'
  });
});

const placesCoordonees = journey?.attributes.sequences
  ? useFindPlacesCoordinates(journey.attributes.sequences)
  : [];

const breadcrumbParts = [
  { name: country?.continent?.name, url: `/${country?.continent?.slug}/` },
  {
    name: country?.name ?? countryContext,
    url: `/${country?.continent?.slug}/${countryContext}/`
  },
  { name: `${journey?.attributes.title}`, url: route.path }
];

let currentStepIndex = $ref(0);
const currentStep = $computed(() => placesCoordonees[currentStepIndex]);

function changeCurrentStep(stepNumber: number) {
  currentStepIndex = stepNumber - 1;
}

const heroJourney = journey?.attributes.sequences
  .map(sequence => ({
    title: sequence.title,
    url: sequence.image
  }))
  .filter(checkSlide);

const heroSejour = [
  {
    title: journey?.attributes.title,
    url: journey?.attributes.heroImage_path
  }
];

const heroImage =
  journey?.attributes?.journeyType === JourneyType.TOUR_SEJOUR ? heroSejour : heroJourney;

/**
 * Checks the slide and its index.
 * returns false if the slide is the first or the last one.
 */
function checkSlide<Type>(slide: Type, index: number): Type | boolean {
  if (index === 0 || index === (journey?.attributes?.sequences?.length ?? 0) - 1) {
    return false;
  }
  return slide;
}

useSeoMeta({
  title: `${journey?.attributes.SEOMeta?.Title ?? journey?.attributes.title}`,
  ogTitle: `${journey?.attributes.SEOMeta?.Title ?? journey?.attributes.title}`,
  description: `${journey?.attributes.SEOMeta?.Description ?? ''}`,
  ogDescription: `${journey?.attributes.SEOMeta?.Description ?? ''}`
});
useHead(() => ({
  link: [
    {
      rel: 'canonical',
      href: `${journey?.attributes.SEOMeta?.Canonical ?? ''}`
    }
  ],
  meta: [
    {
      name: 'robots',
      content: `${journey?.attributes.SEOMeta?.noindex ? 'noindex' : ''} ${journey?.attributes.SEOMeta?.nofollow ? 'nofollow' : ''}`
    }
  ]
}));

// json+ld for journey
useJsonld({
  '@context': 'https://schema.org',
  '@type': 'Product',
  aggregateRating: {
    '@type': 'AggregateRating',
    author: 'Marco Vasco',
    ratingValue: 'TO_BE_DEFINED',
    bestRating: '10',
    worstRating: '0',
    reviewCount: 0 // TO_BE_DEFINED
  },
  description: `${journey?.attributes.teaser}`,
  name: `${journey?.attributes.title}`,
  image: `https://www.marcovasco.fr/${journey?.attributes.titleImage}`,
  offers: {
    '@type': 'Offer',
    price: `${journey?.attributes.price}`,
    priceCurrency: 'EUR',
    priceSpecification: {
      '@type': 'PriceSpecification',
      name: 'Prix à partir de voir conditions sur le site',
      price: `${journey?.attributes.price}`,
      priceCurrency: 'EUR'
    }
  },
  review: [
    {
      '@type': 'Review',
      author: 'TO_BE_EXTRACT_FROM_COMMENT',
      datePublished: 'TO_BE_EXTRACT_FROM_COMMENT',
      reviewBody: 'TO_BE_EXTRACT_FROM_COMMENT',
      name: 'TO_BE_EXTRACT_FROM_COMMENT',
      reviewRating: {
        '@type': 'Rating',
        bestRating: '10',
        ratingValue: 'TO_BE_EXTRACT_FROM_COMMENT',
        worstRating: '0'
      }
    }
  ]
});
</script>

<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/colors.scss';
@use '$/z-index.scss';

#page {
  flex-direction: column;

  .map {
    height: 424px;
    margin-top: 60px;
    object-fit: cover;

    @include breakpoints.mobile() {
      margin-top: 0;
    }
  }
}
</style>
