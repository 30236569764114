import { MvSequence } from '@/lib/strapi-types/components/MvSequence';

import { ItineraryStep } from '@/lib/types/models/common';

export default function useFindPlacesCoordinates(sequences: MvSequence[]): ItineraryStep[] {
  if (sequences.length < 1) return [];
  const placesWithDuplicatesKey = sequences
    .filter((sequence: MvSequence) => {
      return sequence.places.data.length > 0;
    })
    .map(sequence => sequence.places.data)
    .flat();

  const places = [...new Map(placesWithDuplicatesKey.map(place => [place.id, place])).values()];
  let count = 1;
  const placesCoordinates = places.map(place => {
    if (!place.attributes.latitude || !place.attributes.longitude) {
      return null;
    }

    const payload = {
      stepNumber: count,
      location: {
        lat: Number(place.attributes.latitude),
        lng: Number(place.attributes.longitude)
      }
    };
    count++;
    return payload;
  });

  return placesCoordinates.filter(place => place !== null) as ItineraryStep[];
}
