<template>
  <div id="map" ref="element" class="map"></div>
</template>

<script lang="ts" setup>
import * as L from 'leaflet';
// @ts-expect-error
import { MaptilerLayer } from '@maptiler/leaflet-maptilersdk';

import { ItineraryStep } from '@/lib/types/models/common';

const {
  isOverview = false,
  steps,
  width,
  height,
  currentStep
} = defineProps<{
  width: string;
  height: string;
  steps: ItineraryStep[];
  currentStep: ItineraryStep;
  isOverview?: boolean;
}>();
const emit = defineEmits<{
  (event: 'change-current-step', stepNumber: number): void;
  (event: 'toggle-overview'): void;
}>();

const maptilerApiKey = useRuntimeConfig().public.maptilerApiKey;

const id = $ref(`map`);
const element = $ref(null);

let map = $ref<L.Map>();
const group = $ref<L.LayerGroup>(L.layerGroup());

const center = $computed<L.LatLngExpression>(() => {
  const first = steps[0]?.location ?? { lat: 0, lng: 0 };

  const last = steps[steps.length - 1]?.location ?? { lat: 0, lng: 0 };

  return [(first.lat + last.lat) / 2, (first.lng + last.lng) / 2];
});
onMounted(() => {
  update();
  setMarkers();
});
watch($$(element), update, { immediate: true, flush: 'post' });

watch($$(isOverview), () => {
  seeOverview();
  setMarkers();
});

watch($$(currentStep), () => {
  setMarkers();
  zoomOnCurrentStep();
});

function update(): void {
  if (!element) {
    return;
  }

  if (!map) {
    map = L.map(id, { scrollWheelZoom: false }).setView(center, 7);

    const mtLayer = new MaptilerLayer({
      apiKey: `${maptilerApiKey}`,
      style: '1706347a-05cc-4838-bedf-6d79fd28231d' // moustache
    });

    mtLayer.addTo(map);

    setMarkers();
  }
}

function onMarkerClick(stepNumber: number): void {
  if (!map || !Array.isArray(steps) || stepNumber < 1 || stepNumber > steps.length) {
    console.warn('Invalid map object or stepNumber');
    return;
  }

  emit('change-current-step', stepNumber);
  emit('toggle-overview');

  const step = steps[stepNumber - 1];
  const location = step?.location;
  if (location && location.lat != null && location.lng != null) {
    map.setView([location.lat, location.lng], 12);
  } else {
    console.warn('Invalid location for step', stepNumber);
  }
}

function zoomOnCurrentStep(): void {
  if (!map) {
    return;
  }

  map.setView([currentStep.location.lat, currentStep.location.lng], 12);
}

function setMarkers(): void {
  if (!map) {
    return;
  }

  if (group.getLayers().length) {
    group.clearLayers();
  } else {
    group.addTo(map!);
  }

  const polylinePoints: L.LatLngExpression[] = [];
  steps.forEach((step, i) => {
    polylinePoints.push([step.location.lat, step.location.lng]);

    const icon = L.divIcon({
      className:
        i === currentStep.stepNumber - 1 && !isOverview ? 'map-marker-active' : 'map-marker',
      iconAnchor: [0, 24],
      popupAnchor: [0, -36],
      html: `
        <div class="text"></div>
        <div class="triangle" />
      `
    });

    group.addLayer(
      L.marker([step.location.lat, step.location.lng], { icon }).on('click', () =>
        onMarkerClick(step.stepNumber)
      )
    );
  });
  group.addLayer(
    L.polyline(polylinePoints, {
      color: 'var(--color-primary-black)',
      dashArray: '1 5'
    })
  );

  if (polylinePoints.length > 0) {
    if (polylinePoints.length === 1) {
      const singlePoint = polylinePoints[0];
      if (singlePoint) {
        map.setView(singlePoint, 14);
      }
    } else {
      const bounds = L.latLngBounds(polylinePoints);
      map.fitBounds(bounds, { padding: [10, 10] });
    }
  }
}

function seeOverview(): void {
  if (!map) {
    return;
  }

  map.setView(center, 5);
}
</script>

<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/colors.scss';

.map {
  $point-radius: 8px;

  width: v-bind(width);
  height: v-bind(height);

  :deep(*) {
    display: block;
  }

  :deep(.map-marker),
  :deep(.map-marker-active) {
    top: -3px;
    left: -13px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 24px !important;
    height: 24px !important;

    font-size: 19px;
    font-weight: 900;
    color: colors.$primary-black;

    background-color: colors.$primary-black;
    border-radius: 100%;

    &.map-marker-active {
      color: colors.$primary-black;
      background-color: colors.$primary-black;
    }

    .text {
      margin-right: 1px;
    }

    .triangle {
      position: absolute;
      z-index: -1;
      bottom: -2px;
      left: 6px;
      transform: rotate(45deg);

      width: 12px;
      height: 12px;

      background-color: colors.$primary-black;
    }
  }

  :deep(.leaflet-control-zoom) {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 54px;
    height: 104px;

    background-color: white;
    border: none;
    border-radius: $point-radius !important;
    box-shadow: 0 2px 28px rgba(195 201 210 / 50%);

    @include breakpoints.mobile() {
      display: none !important;
    }
  }

  :deep(.leaflet-control-zoom-in) {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100% !important;
    height: 50%;

    border: none !important;
    border-top-left-radius: $point-radius;
    border-top-right-radius: $point-radius;
  }

  :deep(.leaflet-control-zoom-out) {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100% !important;
    height: 50%;

    border: none !important;
    border-bottom-right-radius: $point-radius;
    border-bottom-left-radius: $point-radius;
  }

  :deep(.leaflet-right) {
    display: flex !important;
  }

  :deep(.leaflet-control-attribution) {
    display: flex !important;
  }

  :deep(.leaflet-bar) {
    border-radius: $point-radius !important;

    a:first-child {
      border-top-left-radius: $point-radius !important;
      border-top-right-radius: $point-radius !important;
    }
  }

  :deep(.leaflet-control) {
    border-radius: $point-radius !important;

    a:last-child {
      border-bottom-right-radius: $point-radius !important;
      border-bottom-left-radius: $point-radius !important;
    }
  }
}
</style>
